import { Navigate, Route, Routes } from 'react-router-dom'
import HomePage from '../../pages/home/HomePage'
import InquiryListPage from '../../pages/inquiry/InquiryListPage'

const PageRouting = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {/*<Route path="/comm/my" element={<MyCommPage></MyCommPage>}></Route>*/}
      {/*<Route*/}
      {/*  path="/comm/comment"*/}
      {/*  element={<CommentCommPage></CommentCommPage>}*/}
      {/*></Route>*/}
      {/*<Route path="/mgz/lists" element={<ListsMgzPage></ListsMgzPage>}></Route>*/}
      {/*<Route*/}
      {/*  path="/mgz/comment"*/}
      {/*  element={<CommentMgzPage></CommentMgzPage>}*/}
      {/*></Route>*/}
      {/*<Route path="/mgz/edit/:id" element={<EditMgzPage></EditMgzPage>}></Route>*/}
      {/*<Route*/}
      {/*  path="/mgz/write"*/}
      {/*  element={isLoggedIn ? <WriteMgzPage></WriteMgzPage> : <></>}*/}
      {/*></Route>*/}
      {/*<Route path="/crm/push" element={<CrmPushPage></CrmPushPage>}></Route>*/}
      {/*<Route*/}
      {/*  path="/crm/push-list"*/}
      {/*  element={<CrmPushListPage></CrmPushListPage>}*/}
      {/*></Route>*/}
      <Route path="/inquiry/list" element={<InquiryListPage />} />
      <Route path="*" element={<Navigate to={'/'} replace />} />
    </Routes>
  )
}

export default PageRouting
