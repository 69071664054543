import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_BACKEND_HOST}`

/**
 * 문의 카테고리 목록 조회
 * @param param isTotal: '전체' 카테고리 포함 여부, isAdmin: 관리자 권한 여부
 */
const readCategoryList = async (param) => {
  const res = await axios.get(`${baseUrl}/inquiry/read/list/category`, {
    params: param,
  })
  return res
}

/**
 * 문의 목록 조회
 * @param param offset
 * @param param pageSize
 * @param param answerStatus 문의 답변 여부
 * @param param inquiryType 문의 유형
 * @param param orderType 정렬 유형
 * @param param orderBy 정렬 순서 (desc-최신순/asc-오래된순)
 */
const readInquiryList = async (param) => {
  const res = await axios.get(`${baseUrl}/inquiry/read/list/admin`, {
    params: param,
  })
  return res
}

/**
 * 문의 답변 등록하기
 * @param param id, answer_content, created_by
 */
const createInquiryAnswer = async (param) => {
  const res = await axios.post(`${baseUrl}/inquiry/create/answer`, param)
  return res
}

/**
 * 문의 답변 수정하기
 * @param param id, answer_content, created_by
 */
const editInquiryAnswer = async (param) => {
  const res = await axios.post(`${baseUrl}/inquiry/edit/answer`, param)
  return res
}

const inquiryApi = {
  readCategoryList,
  readInquiryList,
  createInquiryAnswer,
  editInquiryAnswer,
}
export default inquiryApi
