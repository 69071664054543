import React, { useEffect, useState } from 'react'
import { Flex, Input, Pagination, Select } from 'antd'
import { useRecoilState } from 'recoil'
import { userState } from '../../store/user'
import dayjs from 'dayjs'
import ContentHeader from '../../components/shared/ContentHeader'
import inquiryApi from '../../api/inquiry.api'
import '../../styles/inquiry.scss'

const { TextArea } = Input

const InquiryListPage = () => {
  const [user, setUser] = useRecoilState(userState)
  const [isLoading, setIsLoading] = useState(false)
  const [categoryList, setCategoryList] = useState([
    { value: 0, label: '전체' },
  ])
  const [inquiryEndType, setInquiryEndType] = useState(false)
  const [inquiryType, setInquiryType] = useState(0)
  const [dateType, setDateType] = useState('desc')
  const [offset, setOffset] = useState(0)
  const [totalSize, setTotalSize] = useState(1)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [inquiryAnswer, setInquiryAnswer] = useState('')
  const [inquiryAnswerValidation, setInquiryAnswerValidation] = useState(false)

  const [csInquiryList, setCsInquiryList] = useState([])
  const [currentInquiry, setCurrentInquiry] = useState({})

  const pageSize = 10
  const dateItems = [
    { value: 'desc', label: '최신순' },
    { value: 'asc', label: '오래된순' },
  ]

  useEffect(() => {
    readInquiryCategory()
  }, [])

  useEffect(() => {
    readInquiryList()
  }, [inquiryEndType, inquiryType, dateType, offset])

  /**
   * 문의 카테고리 목록 조회
   */
  const readInquiryCategory = async () => {
    try {
      const param = {
        isTotal: true,
        isAdmin: true,
      }
      const res = await inquiryApi.readCategoryList(param)
      if (res.data.code === 200) {
        setCategoryList(
          res.data.result?.categoryList.map((categoryInfo) => ({
            value: categoryInfo.id,
            label: categoryInfo.category_name,
          })),
        )
      } else {
        throw res.data.code
      }
    } catch (e) {
      console.log('InquiryListPage :: readInquiryCategory error = ', e)
      setCategoryList([{ value: 0, label: '전체' }])
    }
  }

  /**
   * 문의 목록 조회
   */
  const readInquiryList = async () => {
    setIsLoading(true)
    try {
      const param = {
        offset: offset,
        pageSize: pageSize,
        answerStatus: inquiryEndType,
        inquiryType: inquiryType,
        orderType: 'created_at',
        orderBy: dateType,
      }
      const res = await inquiryApi.readInquiryList(param)
      if (res.data.code === 200) {
        setCsInquiryList(res.data.result?.inquiryList)
        if (res.data.result.totalCnt !== 0) {
          setTotalSize(res.data.result.totalCnt)
        }
      } else {
        throw res.data.code
      }
    } catch (e) {
      console.log('InquiryListPage :: readInquiryList error = ', e)
      alert(
        '문의 목록을 조회하는데 실패하였습니다. 다시 시도해주시거나 관리자에게 문의해주시길 바랍니다.',
      )
      setCsInquiryList([])
      setTotalSize(1)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 문의 답변 등록/수정하기
   */
  const submitInquiryAnswer = async () => {
    setIsLoading(true)
    try {
      const param = {
        id: currentInquiry.id,
        answer_content: inquiryAnswer,
        created_by: user.id,
      }
      let res
      if (inquiryEndType) {
        res = await inquiryApi.editInquiryAnswer(param)
      } else {
        res = await inquiryApi.createInquiryAnswer(param)
      }
      if (res && res.data.code === 200) {
        let successMsg = '새로운 답변이 등록되었습니다'
        if (inquiryEndType) {
          successMsg = '해당 문의사항에 대한 답변이 수정되었습니다'
        }
        alert(successMsg)
        initialInquiryInfo()
      } else if (res && res.data.code === 400) {
        let errorMsg = '이미 삭제된 문의입니다.'
        if (inquiryEndType) {
          errorMsg = '답변이 등록되지 않은 문의이거나 이미 삭제된 문의입니다.'
        }
        alert(errorMsg)
        initialInquiryInfo()
      } else if (res && res.data.code === 999) {
        alert(res.data.msg)
      } else {
        throw res?.data.code
      }
    } catch (e) {
      console.log('InquiryListPage :: submitInquiryAnswer error = ', e)
      alert('오류가 발생했습니다. 관리자에게 문의하세요')
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 대기중 <-> 답변완료
   * 대기중 : false, 답변완료 : true
   */
  const handleStatusChange = (result) => {
    setInquiryEndType(result)
    handleChangePage(1)
    setInquiryAnswer('')
    setInquiryAnswerValidation(false)
  }

  /**
   * 문의 유형 및 정렬 순서 선택 이벤트 핸들러
   * @param value
   * @param type inquiry/date
   */
  const handleSelectChange = (value, type) => {
    if (type === 'inquiry') {
      setInquiryType(value)
    } else {
      setDateType(value)
    }
    handleChangePage(1)
  }

  /**
   * 문의 답변 text 입력 이벤트 핸들러
   */
  const handleTextChange = (e) => {
    if (e.target.value?.trim() !== '') {
      setInquiryAnswer(e.target.value)
      setInquiryAnswerValidation(true)
    } else {
      setInquiryAnswer('')
      setInquiryAnswerValidation(false)
    }
  }

  /**
   * handleChangePage
   */
  const handleChangePage = (pageNumber) => {
    const offset = (pageNumber - 1) * pageSize
    setCurrentPageNumber(pageNumber)
    setOffset(offset)
  }

  /**
   * 문의 관련 데이터 초기화
   */
  const initialInquiryInfo = () => {
    readInquiryList()
    setCurrentInquiry({})
    setInquiryType(0)
    setDateType('desc')
    handleStatusChange(inquiryEndType)
  }

  return (
    <>
      <ContentHeader title={'회원문의 관리'} isBorder />
      <Flex className={'inquiry-content-wrap'}>
        <div className={'cs-inquiry-menu-wrap'}>
          <Flex
            justify={'space-around'}
            align={'center'}
            className={'cs-inquiry-menu-status-wrap'}
          >
            <div
              onClick={() => handleStatusChange(false)}
              className={`inquiry-main-menu-status-wait ${inquiryEndType ? '' : 'active'}`}
            >
              <div className={'inquiry-main-menu-status-wait-img'}>
                <img
                  src={`${process.env.REACT_APP_HOST}/assets/${
                    inquiryEndType ? 'wait' : 'wait_active'
                  }.png`}
                />
              </div>
              대기중
            </div>
            <div className={'inquiry-main-menu-status-divider'}>|</div>
            <div
              onClick={() => handleStatusChange(true)}
              className={`inquiry-main-menu-status-end ${inquiryEndType ? 'active' : ''}`}
            >
              <div className={'inquiry-main-menu-status-end-img'}>
                <img
                  src={`${process.env.REACT_APP_HOST}/assets/${
                    inquiryEndType ? 'answer_active' : 'answer'
                  }.png`}
                />
              </div>
              답변완료
            </div>
          </Flex>
          <Flex
            justify={'space-between'}
            align={'center'}
            className={'inquiry-main-menu-title-wrap'}
          >
            <Select
              className={'inquiry-main-menu-sort'}
              value={inquiryType}
              options={categoryList}
              onChange={(value) => handleSelectChange(value, 'inquiry')}
            />
            <Select
              className={'inquiry-main-menu-order'}
              value={dateType}
              options={dateItems}
              onChange={(value) => handleSelectChange(value, 'date')}
            />
          </Flex>
          <div className={'inquiry-main-menu-contents-wrap'}>
            {csInquiryList?.length > 0 && (
              <>
                {csInquiryList.map((item) => (
                  <Flex
                    vertical
                    justify={'center'}
                    key={'waitInquiry_' + item.id}
                    onClick={() => setCurrentInquiry(item)}
                    className={`inquiry-main-menu-content-wrap ${
                      Object.prototype.hasOwnProperty.call(
                        currentInquiry,
                        'id',
                      ) && currentInquiry.id === item.id
                        ? 'active'
                        : ''
                    }`}
                  >
                    <div>
                      <div className={'inquiry-main-menu-info'}>
                        [{item.category_name}] {item.name}
                      </div>
                      <div className={'inquiry-main-menu-content'}>
                        {item.content}
                      </div>
                    </div>
                    <div className={'inquiry-main-menu-date'}>
                      {dayjs(item.created_at).format('YYYY/MM/DD HH:mm')}
                    </div>
                  </Flex>
                ))}
              </>
            )}
          </div>
          <Flex
            justify={'center'}
            align={'center'}
            className={'cs-inquiry-main-menu-pagination-wrap'}
          >
            <Pagination
              size="small"
              total={totalSize}
              simple
              pageSize={pageSize}
              onChange={handleChangePage}
              current={currentPageNumber}
              style={{ display: 'flex' }}
            />
          </Flex>
        </div>
        <Flex vertical className={'cs-inquiry-content-wrap'}>
          <Flex
            justify={'space-between'}
            align={'center'}
            className={'inquiry-main-content-header-wrap'}
          >
            <div className={'inquiry-main-content-info-wrap'}>
              {currentInquiry && Object.keys(currentInquiry)?.length !== 0 && (
                <>
                  <div className={'cs-inquiry-content-info-title'}>
                    [{currentInquiry.category_name}] {currentInquiry.name}{' '}
                    문의사항
                  </div>
                  {currentInquiry.company_name && (
                    <div className={'cs-inquiry-content-info-company'}>
                      {currentInquiry?.company_name}{' '}
                      {currentInquiry?.company_part} {currentInquiry?.name} /{' '}
                      {currentInquiry?.phone}
                    </div>
                  )}
                </>
              )}
            </div>
          </Flex>
          <Flex
            vertical
            justify={'space-between'}
            className={'inquiry-main-content-wrap'}
          >
            <div className={'inquiry-main-content-chat-wrap'}>
              {currentInquiry && Object.keys(currentInquiry)?.length !== 0 && (
                <Flex className={'user-inquiry-text-wrap'}>
                  <pre className={'user-inquiry-text'}>
                    {currentInquiry.content}
                  </pre>
                  <div className={'inquiry-text-date'}>
                    {dayjs(currentInquiry.created_at).format(`YY-MM-DD`)}
                  </div>
                </Flex>
              )}
              {currentInquiry && currentInquiry?.is_answer && (
                <Flex
                  justify={'flex-end'}
                  className={'admin-inquiry-text-wrap'}
                >
                  <div className={'inquiry-text-date'}>
                    {dayjs(currentInquiry.answer_date).format(`YY-MM-DD`)}
                  </div>
                  <div className={'admin-inquiry-text'}>
                    {currentInquiry.answer_content}
                  </div>
                </Flex>
              )}
            </div>
            <Flex vertical className={'inquiry-main-content-answer-wrap'}>
              <Flex className={'inquiry-main-content-answer-text-wrap'}>
                <Flex className={'inquiry-main-content-answer-box'}>
                  <TextArea
                    onChange={handleTextChange}
                    allowClear
                    type="text"
                    placeholder={
                      inquiryEndType
                        ? '답변을 수정하세요.'
                        : '답변을 입력하세요'
                    }
                    disabled={isLoading}
                    value={inquiryAnswer}
                    style={{
                      resize: 'none',
                      height: '11vh',
                    }}
                  />
                </Flex>
                <div className={'inquiry-main-content-action-wrap'}>
                  <Flex
                    justify={'center'}
                    align={'center'}
                    onClick={() => {
                      if (inquiryAnswerValidation && !isLoading) {
                        submitInquiryAnswer()
                      }
                    }}
                    className={`inquiry-main-content-action ${inquiryAnswerValidation && !isLoading ? 'active' : ''}`}
                  >
                    전송
                  </Flex>
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default InquiryListPage
